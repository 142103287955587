import { EmailAuthProvider, getAuth, signInWithEmailAndPassword, User as FirebaseUser } from "firebase/auth";
import LogoLight from './assets/logo_light.png'; 
import LogoDark from './assets/logo_dark.png';
import config from "./utils/config";
import * as React from 'react';

import Search from "./utils/search";
import Decks from "./collections/decks";
import Cards from "./collections/cards";
import Sets from "./collections/sets";
import Store from "./collections/store";
import Boosters from "./collections/boosters";
import Users from "./collections/users";
import Quests from "./collections/quests";
import CardsFeedback from "./collections/cardsFeedback"
import CustomAuthenticator from "./utils/CustomAuthenticator";
import { Modal } from "./views/Modal";
import { GoogleAuthProvider } from "firebase/auth";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { FirebaseAuthDelegate, Authenticator, CircularProgressCenter, createCMSDefaultTheme, FirebaseLoginView, FireCMS, NavigationBuilder, NavigationBuilderProps, NavigationRoutes, Scaffold, SideEntityDialogs, useFirebaseAuthDelegate, useFirebaseStorageSource, useFirestoreDataSource, useInitialiseFirebase, CMSView } from "@camberi/firecms";
import { firebaseConfig, firebaseLiveConfig } from "./firebaseConfig";
import { getFirestore, doc, getDoc, query, collection, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import defaultDecks from "./collections/defaultDecks";
import Config from "./utils/config";
import { Dashboard } from "./views/Dashboard";
//import sendLOST from "./collections/sendLOST";
import Settings from "./collections/settings";
import Soulbound from "./collections/soulbound";

import { getStorage } from "firebase/storage";
import Ranks from "./collections/ranks";
import RankCategories from "./collections/rankCategories";
import Seasons from "./collections/seasons";
import Bridges from "./collections/bridges";

 
const DEFAULT_SIGN_IN_OPTIONS = [GoogleAuthProvider.PROVIDER_ID, EmailAuthProvider.PROVIDER_ID];


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const devStorage = getStorage(app);
const devauth = getAuth(app);
config.setDevDB(db);
config.setDevStorage(devStorage);
config.setDevAuth(devauth);


const liveApp = initializeApp(firebaseLiveConfig, "live");
const liveDb = getFirestore(liveApp);
const liveStorage = getStorage(liveApp);
config.setLiveStorage(liveStorage);
const auth = getAuth(liveApp);
config.setDevStorage(devStorage);
config.setLiveDB(liveDb);

// sign in as superadmin to live
let superadmin:string = (process.env.REACT_APP_SUPERADMIN == undefined) ? "" : process.env.REACT_APP_SUPERADMIN;
let superpass:string = (process.env.REACT_APP_SUPERADMINPASS == undefined) ? "" : process.env.REACT_APP_SUPERADMINPASS;
signInWithEmailAndPassword(auth, superadmin, superpass)
  .then((userCredential) => {
    console.log ("live user logged in")
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log (errorCode, errorMessage);
  });







export default function App() {

    const search = new Search();
    const [open, setOpen] = React.useState(false); // boolean to toggle the modal
    const [modalData, setModalData] = React.useState([]); // data for the modal
    //const [walletConnected, setWalletConnected] = React.useState(false); // the wallet connect status
    //const [walletBalances, setWalletBalances] = React.useState(); // the wallet connect status
    const [metrics, setMetrics] = React.useState(); // the booster pack analytics
    const [rewardWallets, setRewardWallets] = React.useState(); // the list of all wallets we sent LOST to
    const [totalUsers, setTotalUsers] = React.useState(); // the number of total registered users
    const [currencyExchange, setCurrencyExchange] = React.useState(); // exchange currencies
    const [percentageComplete, setPercentageComplete] = React.useState(Number); // the progress of sending $LOST to multiple wallets

    const customViews: CMSView[] = [{
        path: ["dashboard", "additional/:id"],
        name: "Dashboard",
        description: "Game Dashboard",
        group:"Settings",
        view: <Dashboard setOpen={setOpen} setModalData={setModalData} />
    }];

//
//
// NAVIGATION    
//
//
    const navigation: NavigationBuilder = async ({ user}: NavigationBuilderProps) => {
        let userdocid = String(user?.uid);
        let docRef = doc(db, "users", userdocid);
        let docSnap = await getDoc(docRef);
        let isadmin = false;
        let issuperadmin = false;
        
        
        
        let userrole = await Config.getUserRoles(user);
        if (docSnap.exists() ) isadmin = userrole.includes("admin") || userrole.includes("superadmin");
        if (docSnap.exists() ) issuperadmin = userrole.includes("superadmin");
        
        return ({
            views: customViews,
            collections: [
                Sets.BuildCollection(setOpen, setModalData, issuperadmin, issuperadmin, "Cards database"),
                Cards.BuildCollection(isadmin, issuperadmin, "Cards database"),
                CardsFeedback.BuildCollection(setOpen, setModalData, isadmin, issuperadmin, "Cards database"),
                
                Decks.BuildCollection(setOpen, setModalData, isadmin, issuperadmin, "Decks database"),
                defaultDecks.BuildCollection(setOpen, setModalData, isadmin, issuperadmin, "Decks database"),

                Boosters.BuildCollection(setOpen, setModalData, isadmin, issuperadmin, setRewardWallets, setMetrics, setTotalUsers,setCurrencyExchange,"Packs & cosmetics"),
                Soulbound.BuildCollection(isadmin, issuperadmin, "Packs & cosmetics"),
                Bridges.BuildCollection(isadmin, issuperadmin, "Packs & cosmetics"),

                Users.BuildCollection(isadmin, issuperadmin, "Users & Rewards"),
                Quests.BuildCollection(isadmin, issuperadmin, "Users & Rewards"),


                Ranks.BuildCollection(isadmin, issuperadmin, "Ranking"),
                RankCategories.BuildCollection(isadmin, issuperadmin, "Ranking"),

                
                Store.BuildCollection(isadmin, issuperadmin, "Settings"),
                Seasons.BuildCollection(isadmin, issuperadmin, "Settings"),
                Settings.BuildCollection(isadmin, issuperadmin, "Settings"),
                //sendLOST.BuildCollection(isadmin, issuperadmin, walletBalances, setWalletBalances, setModalData, setOpen, percentageComplete, setPercentageComplete, "Settings")
            ]
            
        });
    };

   

//
//
// AUTHENTICATION    
//
//    
    const authenticator: Authenticator<FirebaseUser> = async ({user,authController}) => {
        let customauth = new CustomAuthenticator(user, authController);
        let response = await customauth.getUserFromFirestore();
        return response;                                                                         
    };


//
//
// INITIALISATION    
//
//    
    
    const {firebaseApp,firebaseConfigLoading,configError,firebaseConfigError} = useInitialiseFirebase({ firebaseConfig });
    const signInOptions = DEFAULT_SIGN_IN_OPTIONS;
    const authDelegate: FirebaseAuthDelegate = useFirebaseAuthDelegate({firebaseApp,signInOptions});
    const dataSource = useFirestoreDataSource({firebaseApp: firebaseApp,textSearchController:search.controller});
    const storageSource = useFirebaseStorageSource({ firebaseApp: firebaseApp });

//
//
// ERROR REPORTING    
//
//    
    if (configError) {return <div> {configError} </div>;}
    if (firebaseConfigError) {
        return <div>
            It seems like the provided Firebase config is not correct. If you
            are using the credentials provided automatically by Firebase
            Hosting, make sure you link your Firebase app to Firebase
            Hosting.
        </div>;
    }
    if (firebaseConfigLoading || !firebaseApp) {return <CircularProgressCenter/>;}

    
    return (
    <div>
      <div id="recaptcha"></div>
        <Router>
            <FireCMS navigation={navigation}
                     authDelegate={authDelegate}
                     authentication={authenticator}
                     dataSource={dataSource}
                     storageSource={storageSource}
                     entityLinkBuilder={({ entity }) => `https://console.firebase.google.com/project/${firebaseApp.options.projectId}/firestore/data/${entity.path}/${entity.id}`}>
                {({ context, mode, loading }) => {
                    
                    const theme = createCMSDefaultTheme({ mode });
                    let variableLogo = (mode == "dark") ? LogoLight : LogoDark;
                    let component;
                    if (loading) { 
                        component = <CircularProgressCenter/>;
                    } else if (!context.authController.canAccessMainView) {
                        component = (
                            <FirebaseLoginView
                                allowSkipLogin={false}
                                signInOptions={signInOptions}
                                firebaseApp={firebaseApp}
                                logo={variableLogo}
                                authDelegate={authDelegate}/>
                        );
                    } else {
                        component = (
                            <Scaffold name={"TLG CMS"} logo={variableLogo}>
                                <NavigationRoutes/>
                                <SideEntityDialogs/>
                            </Scaffold>
                        );
                    }

                    return (
                        <ThemeProvider theme={theme}>
                            <Modal
                            theme={theme}
                            open={open} 
                            setOpen={setOpen}
                            data={modalData}
                            metrics={metrics}
                            rewards={rewardWallets}
                            totalUsers={totalUsers}
                            currencyExchange={currencyExchange}
                            ></Modal>
                            <CssBaseline/>
                            {component}
                        </ThemeProvider>
                    );
                }}
            </FireCMS>

        </Router>
    
    
    </div>
    );

}




