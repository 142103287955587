import { buildSchema,buildProperty, buildCollection,ExtraActionsParams } from "@camberi/firecms";





export default class Soulbound {

    constructor() {

    }

    static BuildCollection (isadmin:boolean, issuperadmin:boolean, groupname:string) {
      return buildCollection({
        path: "soulbound",
        description: "collection of soulbound rewards to be claimed daily",
        group:groupname,
        exportable:false,
        schema: Soulbound.schema(),
        name: "Soulbound",
        permissions: ({entity,path,user,authController,context}) => { 
            return ({
                read: isadmin,
                create: isadmin,
                update: isadmin,
                delete: isadmin
            });
        },
      })
    }


   
    

    static schema () {
        return buildSchema({
            name: "Soulbounds",
            properties: {
              
              name: {
                title: "Name",
                dataType: "string"
              },
              description: {
                title: "Description",
                dataType: "string"
              },
              nftID: {
                title: "NFT ID (only numbers)",
                dataType: "string"
              },
              amountToMint: {
                title: "How many should we mint",
                dataType: "number"
              },
              image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
                title: "image",
                dataType: "string",
                config: {
                    storageMeta: {
                        mediaType: "image",
                        storagePath: "soulbound",
                        acceptedFiles: ["image/*"]
                    }
                }
              }),
              showIn: {
                title: "Reward Type",
                dataType: "array",
                of: {
                  dataType: "string",
                  config: {
                    enumValues: {
                      "ingame":"in-game",
                      "startgame-memory-fragments":"start game: memory fragments",
                      "hourly-memory-fragments":"hourly memory fragments",
                      "ingame-win":"in-game win",
                      "ingame-loss":"in-game loss",
                      "webstore": "webstore",
                      "standalone": "standalone",
                    }
                  }
                }
              },

              notes: {
                title: "Notes soulbound",
                dataType: "string"
              },
              
              
            
                
            }
          });
    }


    
}