import * as React from 'react';
import Grid from '@mui/material/Grid';
import {Typography, TextField, Box, CircularProgress } from '@mui/material';
import { collection,collectionGroup, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';
import config from "../utils/config";
import DashboardTile from "./DashboardTile";
import Config from '../utils/config';
import { useAuthController } from '@camberi/firecms';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeEn from 'air-datepicker/locale/en';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title } from 'chart.js';
import 'chart.js/auto'; // ADD THIS


let _setOpen:any;
let _setModalData:any;
let authController:any;
let totalPlayers:any;
let seasonPlayers:any;
let defaultGraphOptions = {
    responsive: true,
    plugins: {
        legend: {
        position: 'top' as const,
        },     
    },
};  

let defaultGraph = {
      datasets: [{
        data: [0],
      }],
      labels: ['no date']
  }

export function Dashboard(props: {setOpen:any, setModalData:any}) {
    _setOpen = props.setOpen;
    _setModalData = props.setModalData;
    authController = useAuthController();
    

    const [setFields, setSetFields] = React.useState([]); // the dev collections
    const [showDevMetrics, setShowDevMetrics] = React.useState(false);
    const [showExtMetrics, setShowExtMetrics] = React.useState(false);
    const [coreDevMetrics, setCoreDevMetrics ] = React.useState([]);
    const [extendedDevMetrics, setExtendedDevMetrics ] = React.useState([]);
    const datePickerRef = React.useRef<HTMLDivElement>(null);
    


    React.useEffect(()=>{
        getSetsCollection("set", "sets", setSetFields);
       
        getCoreDevMetrics(setCoreDevMetrics, setShowDevMetrics);

   
        if (datePickerRef.current != null) datePickerRef.current.innerHTML = "";
        new AirDatepicker('#datepicker', {
            range: true,
            multipleDatesSeparator: ' - ',
            locale: localeEn,            
            buttons: [
                {
                    content(dp) {
                        return "get data";
                    },
                    onClick(dp) {
                        if (dp.selectedDates[0] == undefined || dp.selectedDates[1] == undefined) return;
                        getExtendedDevMetrics(setExtendedDevMetrics, dp.selectedDates[0], dp.selectedDates[1], setShowExtMetrics);
                        dp.hide();

                    }
                }
            ]
        });
        
    }, []);


    

    let displayDevMetrics = (showDevMetrics == true) ? "none" : "block";
    
    return (
        <Grid container sx={{ m:4, width:"auto"}}>
            <CircularProgress sx={{display:displayDevMetrics}} />
            
            { renderPlaytestingStats (coreDevMetrics, extendedDevMetrics, showDevMetrics, showExtMetrics) }
            { renderSegment("sets", setFields) }
           
        </Grid>
    );
}





const renderPlaytestingStats = (coreDevMetrics:any, extendedDevMetrics:any, showDevMetrics:boolean, showExtMetrics:boolean) => {
    
    let displayDevMetrics = (showDevMetrics == true) ? "block" : "none";
    let displayExtMetrics = (showExtMetrics == true) ? "block" : "none";
    
    return(<span>
        
        
        <Grid container spacing={2}>
            <Typography 
            sx={{ width:"100%", margin:"0 25px"}} 
            variant="h5" 
            component="h1">Game Stats (live server)</Typography>


            <Typography sx={{ width:"100%", margin:"0 25px", display: displayDevMetrics}} component={"span"} >
                We have {coreDevMetrics.registeredUsers} registered players.<br/>
                {coreDevMetrics.playedAtLeastOneMatch} of which have played at least one game: <br/>
                <Typography component="ul">
                <Typography key="0" component="li">{coreDevMetrics.lessThan5} have played less than 5 games</Typography>
                <Typography key="1" component="li">{coreDevMetrics.lessThan20} have played less than 20 games</Typography>
                <Typography key="2" component="li">{coreDevMetrics.lessThan50} have played less than 50 games</Typography>
                <Typography key="3" component="li">{coreDevMetrics.moreThan50} have played more than 50 games</Typography>
                <Typography key="4" component="li">{coreDevMetrics.moreThan100} have played more than 100 games</Typography>
                </Typography>
                <br/>
                Our players have played {coreDevMetrics.matchesPlayed} games and completed {coreDevMetrics.completedQuests} quests. 
            </Typography>
            


            

            {/*  date range widget of daily logins */}
            <TextField InputLabelProps={{ shrink: true }} sx={{width:"250px", margin:"25px", display: displayDevMetrics}} id="datepicker" label="select date range to get data " variant="outlined"/>
            <Typography 
            sx={{ width:"100%", margin:"0 25px", display: displayExtMetrics}} 
            >
                {extendedDevMetrics.registeredUsers} players registered during this period. Matches during this period lasted an average of {extendedDevMetrics.averageMinutes} minutes and took an average of {extendedDevMetrics.averageTurns} turns.
                </Typography>
            
            <Box sx={{ height:"500px", width:"100%", margin:"0 25px", display: displayExtMetrics}}> 
             { <Line options={(extendedDevMetrics.graphData == undefined) ? defaultGraphOptions : extendedDevMetrics.graphOptions} data={ (extendedDevMetrics.graphData == undefined) ? defaultGraph : extendedDevMetrics.graphData}  /> }
            </Box>
            
    
      </Grid>

        </span>
        
    )
}



const renderSegment = (collectionname:string, fields:any) => {
    return(<span>
        
        
        <Grid container spacing={2}>
            <Typography 
            sx={{ width:"100%", margin:"25px"}} 
            variant="h5" 
            component="h1">Compare {collectionname} to live server.</Typography>
            
        
            {fields.map((field:any,i:any) => {
                let key = collectionname+"-"+i;
                let title = (collectionname != "defaultdecks") ? field.name : field.title;
                return(
                    <DashboardTile key={key}
                    index={i}
                    title= {title}
                    subtitle = {field.description}
                    collection = {collectionname}
                    fields = {field}
                    setOpen = {_setOpen}
                    setModalData = {_setModalData}
                    ></DashboardTile>
                )
            })}
      </Grid>

        </span>
        
    )

}

const getSetsCollection = async (type:string, collectionname:string, setFields:any) => {
    let promise = new Promise(async (resolve, reject) => { 
      // get public documents      
      let q = query(collectionGroup(config.getDevDB(), "public"), where("type", "==", type) );    
      let querySnapshot = await getDocs(q);
      let currentCollection :any []= [];
      await querySnapshot.forEach((doc) => {
        currentCollection.push(doc.data().docid);
      });
    // get data of public documents 
      let collectionArray : any = [];
      let i = 0;
      currentCollection.forEach( async ( publicID) => {

          let ref = await getDoc(doc(config.getDevDB(), collectionname, publicID));
          
          let data:any = ref.data();
          
          if (data == undefined) return;
          data.docid = ref.id;
         // if (data.abbreviation != undefined) { // this mean we're getting the reference set and not the subcollection
            collectionArray.push(data);
            i++;
            if (i >= currentCollection.length) {
                resolve("success");
            }
            setFields(collectionArray);
        //  }
          
      })
    });
  
    let result = await promise;
    return result;  
};


const getCoreDevMetrics = async (setMetrics:any, setDevMetricsVisibility:any) => {
    let matchesPlayed = 0;
    let playedAtLeastOneMatch = 0;
    let matchesPlayedIndividual : any = [];
    let metricsQuery = query(collectionGroup(Config.getLiveDB(), "metrics") );
    let metricsSnapshot = await getDocs(metricsQuery);

    
    let users = await getUsers();
    
    metricsSnapshot.forEach((doc) => {
        if (doc.id == "gameplay") {
            if (doc.data().matchesPlayed != undefined) {
                matchesPlayed += doc.data().matchesPlayed;
                if ( doc.data().matchesPlayed > 0) {
                    matchesPlayedIndividual.push ( doc.data().matchesPlayed);
                    playedAtLeastOneMatch++;
                }
            }
        }
        
        
    })

    let lessThan5:number=0,lessThan20:number=0,lessThan50:number=0,moreThan50:number=0,moreThan100:number=0;
    matchesPlayedIndividual.forEach((nr:any) => {
        if (nr < 5 && nr > 0) lessThan5++;
        if (nr >= 5 && nr < 20 ) lessThan20++;
        if (nr >= 20 && nr < 50 ) lessThan50++;
        if (nr >= 50 && nr < 100 ) moreThan50++;
        if (nr >= 100) moreThan100++;

        
    });
    // less than 5
    // less than 20
    // less than 50
    // more than 100

    let completedQuestsQuery = query(collectionGroup(Config.getLiveDB(), "completedQuests") );
    let QuestsSnapshot = await getDocs(completedQuestsQuery);
    

    let data = {
        registeredUsers: users.registeredUsers,
        matchesPlayed: Math.round(matchesPlayed/2), // divided by two because one match is per player
        completedQuests: QuestsSnapshot.size,
        playedAtLeastOneMatch: playedAtLeastOneMatch,
        lessThan5:lessThan5,
        lessThan20:lessThan20,
        lessThan50:lessThan50,
        moreThan50:moreThan50,
        moreThan100:moreThan100
    }
    totalPlayers = users.registeredUsers;
    seasonPlayers = playedAtLeastOneMatch;
    setMetrics(data);
    setDevMetricsVisibility(true);
}


const getExtendedDevMetrics = async (setMetrics:any, fromDate:any, toDate:any, setExtDevMetricsVisibility:any) => {
    ChartJS.register(LineElement, PointElement, LinearScale, Title);


    let users = await getUsers(fromDate, toDate);
    
    let dailyLogins:any = [];

    let dailyLoginsQuery = query(collectionGroup(Config.getLiveDB(), "logins"), where("loginTime", ">", fromDate), where("loginTime", "<", toDate), orderBy("loginTime") );
    let loginsSnapshot = await getDocs(dailyLoginsQuery);
    loginsSnapshot.forEach((doc) => {
        dailyLogins.push(new Date(doc.data().loginTime.seconds*1000).toDateString());
    })

    dailyLogins.sort(function(a:any,b:any){return Date.parse(b.date) - Date.parse(a.date);});
    var countDailyLogins:any = {};
    for (var i = 0; i < dailyLogins.length; i++) {
        countDailyLogins[dailyLogins[i]] = 1 + (countDailyLogins[dailyLogins[i]] || 0);        
    }
    
    let labels = Object.keys(countDailyLogins);



    let dailyMatchHistory:any = [];
    let dailyTurnsPlayed: any = [];
    let dailySecondsPlayed: any = [];
    let mathHistoryQuery = query(collectionGroup(Config.getLiveDB(), "matchHistory"), where("startDate", ">", fromDate), where("startDate", "<", toDate), orderBy("startDate") );
    let mathHistorySnapshot = await getDocs(mathHistoryQuery);

    
    mathHistorySnapshot.forEach((doc) => {
        dailyMatchHistory.push(new Date(doc.data().startDate.seconds*1000).toDateString());
        dailyTurnsPlayed.push ( doc.data().TurnsOnEnd);
        dailySecondsPlayed.push ( Math.round (doc.data().MatchDurationInMinutes * 60) );
        
    })
    dailyMatchHistory.sort(function(a:any,b:any){return Date.parse(b.date) - Date.parse(a.date);});

    
    var countDailyMatchHistory:any = {};
    for (var i = 0; i < dailyMatchHistory.length; i++) {
        let count = 1 + (countDailyMatchHistory[dailyMatchHistory[i]] || 0);    
        countDailyMatchHistory[dailyMatchHistory[i]] = count; 
    }
    
    // divide daily match history /2 since both players have records
    Object.keys(countDailyMatchHistory).forEach((item) => {
        if(typeof countDailyMatchHistory[item] == "number" ) {
            countDailyMatchHistory[item] = Math.round(countDailyMatchHistory[item]/2);
        }
    })

    
    let datasets = [
        {data: Object.values(countDailyLogins), label:"nr. of users logged in the game"},
        {data: Object.values(countDailyMatchHistory), label:"nr. of games played"}
    ];
    

    const calcAverage = function (array:any) { 
        let sum = 0; 
        for (let i = 0; i < array.length; i++) {
            if (array[i] >= 0) sum += array[i]; 
        }
         
        const average = sum / array.length;  
         
        return average; 
    }; 

    console.log("daily logins", countDailyLogins);
    console.log("daily matches played", countDailyMatchHistory);
    console.log ("total players",  totalPlayers );
    console.log ("season players",  seasonPlayers );
    console.log ("turns played",Math.round(calcAverage(dailyTurnsPlayed)));
    console.log ("seconds played", calcAverage(dailySecondsPlayed)/60);
    console.log ("datasets",  datasets );
    
    let graphData = {labels,datasets};
    let graphOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },  
            tooltip: {
                callbacks: {
                //   footer: (items:any) => {
                //     return Math.round(items[0].raw*100/totalPlayers) + " % of total players. \n" + Math.round(items[0].raw*100/seasonPlayers) + " % of logged in players.";                    
                //   },
                }
              }   
        },
    };  

    
    let data = {
        dailyLogins: countDailyLogins,
        registeredUsers: users.registeredUsers,
        loggedInUsers: users.loggedInUsers,
        graphData: graphData,
        averageTurns: Math.round(calcAverage(dailyTurnsPlayed)),
        averageMinutes: Math.round(calcAverage(dailySecondsPlayed)/60),
        graphOptions: graphOptions
    }

    setExtDevMetricsVisibility(true);
    setMetrics(data);
}


const getUsers = async (fromDate?:string, untilDate?:string) => {
    let registeredUsers = 0;
    let loggedInUsers = 0;
    let notLoggedInEmails : any = [];

    let fd, ud;
    if (fromDate != undefined && untilDate != undefined) {
        fd = new Date (fromDate);
        ud = new Date (untilDate);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: {
              amountOfUsers: 1000, 
              fromDate: fd,
              untilDate: ud,
              userID: "qJn87wGx2DULnsS4fJSFJbMo3kV2"
            } })
          };
            try {
                await fetch('https://europe-west3-tlg-live.cloudfunctions.net/listUsers', requestOptions)
                .then(response => response.json())
                .then( (res) => {
                    registeredUsers = res.result.users.length;
        
                    res.result.users.forEach((user:any) => {
                        if (user.metadata != null) {
                            //registeredUsers++;
                            if (user.metadata.lastSignInTime != null) {
                                loggedInUsers++;    
                            } else {
                                
                                notLoggedInEmails.push(user.email);
                            }
                        } 
                        
                    })
                    
                } );
            } catch (e:any) {
                console.log (e);
                
            }
    } else {
        let ref = await getDoc(doc(config.getLiveDB(), "settings", "playtesting"));
        let data:any = ref.data();

        loggedInUsers = 0;
        registeredUsers = data.registeredUsers;
        
    }

    
    


    

    //  console.log("USER NOT LOGGED IN", notLoggedInEmails);
      return {
        loggedInUsers: loggedInUsers,
        registeredUsers: registeredUsers,
        
      }

      
}
